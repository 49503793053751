import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/layout/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout seoTitle="Mileage Metrics" description="Mileage Metrics" type="📱" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mileage-metrics"
    }}>{`Mileage Metrics`}</h1>
    <p>{`Summarize your HealthKit running workouts and get stats for your overall mileage for the year, the week, or all time. Add a widget and stay motivated as you see your total grow. Stay on top of your current training load with a graph of your running mileage over the last 4 weeks.`}</p>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <p>{`For assistance with Mileage Metrics, contact help(at)dschapman.com.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      